import React, { useState } from 'react';
import '../Afooter/Afooter.css';

function Afooter() {
  const [formData, setFormData] = useState({});

  const fchange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const [fres, setfres] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/uploadfooter`, {
        method: 'POST',
        body: data,
      });
      if (response.ok) {
        const result = await response.json();
        setfres(result.message);
        setTimeout(() => {
          setfres('');
        }, 2000);
      } else {
        console.error('Failed to upload footer data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="Afootermain">
        <br/>
        <form method='POST' onSubmit={handleSubmit}>

        { fres && <div style={{width:'100%', textAlign:'center', padding:'0.5rem', backgroundColor:'orange', color:'white', fontSize:'21px'}}>
          {fres}
        </div>}

          <div className="a1">
            <div>
              <label>Upload Image 1:</label><br/>
              <input type="file" accept="image/*" name="i1image" onChange={fchange}  />
            </div>
            <div>
              <input type="text" placeholder='Image 1 Title' name="i1title" onChange={fchange}  />
            </div>
            <div>
              <input type="text" placeholder='Image 1 description' name="i1description" onChange={fchange}  />
            </div>
            <div>
              <input type="text" placeholder='URL Link' name="i1url" onChange={fchange}  />
            </div>
          </div>
          <div className="a2">
            <div>
              <label>Upload Image 2:</label><br/>
              <input type="file" accept="image/*" name="i2image" onChange={fchange}  />
            </div>
            <div>
              <input type="text" placeholder='Image 2 Title' name="i2title" onChange={fchange}  />
            </div>
            <div>
              <input type="text" placeholder='Image 2 description' name="i2description" onChange={fchange}  />
            </div>
            <div>
              <input type="text" placeholder='URL Link' name="i2url" onChange={fchange}  />
            </div>
          </div>
          <div className="a3">
            <div>
              <label>Upload Image 3:</label><br/>
              <input type="file" accept="image/*" name="i3image" onChange={fchange}  />
            </div>
            <div>
              <input type="text" placeholder='Image 3 Title' name="i3title" onChange={fchange}  />
            </div>
            <div>
              <input type="text" placeholder='Image 3 description' name="i3description" onChange={fchange}  />
            </div>
            <div>
              <input type="text" placeholder='URL Link' name="i3url" onChange={fchange}  />
            </div>
          </div>
          <div className="a4">
            <div>
              <label>Footer Logo:</label><br/>
              <input type="file" accept="image/*" name="footerlogo" onChange={fchange}  />
            </div>
          </div>
          <div className="a5">
            <input type="text" placeholder='Pricing Tagline' name="pricetagline" onChange={fchange}  />
            <input type="text" placeholder='Services Tagline' name="servicetagline" onChange={fchange}  />
            <input type="text" placeholder='Profiler Tagline' name="protagline" onChange={fchange}  />
            <input type="text" placeholder='Contact Tagline' name="contagline" onChange={fchange}  />
            <input type="text" placeholder='Company Tagline' name="companytagline" onChange={fchange}  />
            <input type="text" placeholder='Address Tagline' name="addresstagline" onChange={fchange}  />
            <input type="text" placeholder='Phone number Tagline' name="phonetagline" onChange={fchange}  />
            <input type="text" placeholder='Email Tagline' name="etagline" onChange={fchange}  />
          </div>
          <div className="a6">
            <button type="submit">Save and Upload</button>
          </div>
          <br/>
        </form>
      </div>
    </>
  );
}

export default Afooter;
