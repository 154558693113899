import React, { useEffect, useState } from 'react';
import '../Footer/Footer.css';
import {  IoLogoWhatsapp, IoPricetagsSharp } from "react-icons/io5";
import { FaFacebook, FaLinkedinIn, FaLocationDot } from "react-icons/fa6";
import { PiIdentificationCardFill } from "react-icons/pi";
import { MdEmail, MdContactPhone } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import { GrServices } from "react-icons/gr";
import { AiFillProfile } from "react-icons/ai";
import { FaPhone } from "react-icons/fa6";


function Footer() {
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getfooter`);
        if (response.ok) {
          const data = await response.json();
          setFooterData(data);
        } else {
          console.error('Failed to fetch footer data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchFooterData();
  }, []);

  if (!footerData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="truckingfooter">
        <div className="fr1">
          {"Increase your business with professional team"}
        </div>

        <div className="fr2">
          <a href={footerData.Ii1url} style={{textDecoration:'none', color:'inherit'}}>
            <div className="boxf">
              {/* <img src={`${process.env.REACT_APP_API_URL}/${footerData.Ii1image}`} style={{width:'60px'}} /> */}
              <img src="/img1f.png" style={{width:'60px'}} />
              <h4>{footerData.Ii1title}</h4>
              {footerData.Ii1description}
            </div>
          </a>

          <a href={footerData.Ii2url} style={{textDecoration:'none', color:'inherit'}}>
          <div className="boxf">
              {/* <img src={`${process.env.REACT_APP_API_URL}/${footerData.Ii2image}`} style={{width:'60px'}} /> */}
              <img src="/facewbook.png" style={{width:'60px'}} />
              <h4>{footerData.Ii2title}</h4>
              {footerData.Ii2description}
            </div>
          </a>

          <a href={footerData.Ii3url} style={{textDecoration:'none', color:'inherit'}}>
          <div className="boxf">
              {/* <img src={`${process.env.REACT_APP_API_URL}/${footerData.Ii3image}`} style={{width:'60px'}} /> */}
              <img src="/linkedin.png" style={{width:'60px'}} />
              <h4>{footerData.Ii3title}</h4>
              {footerData.Ii3description}
            </div>
          </a>
        </div>

        <div className="fr3">
          <NavLink to="/Dispatch/Dmain" style={{textDecoration:'none', color:'inherit'}}>
            <div className="left">
            {/* <img src={`${process.env.REACT_APP_API_URL}/${footerData.Ffooterlogo}`} style={{width:'150px'}} /> */}
            <img src="/tlogo.png" style={{width:'150px'}} />
            </div>
          </NavLink>
          <div className="center">
            <ul>
              <li><IoPricetagsSharp style={{fontSize:'20px'}}/> {footerData.Ppricetagline || "Truck Dispatcher Pricing"}</li>
              <li><GrServices style={{fontSize:'20px'}}/> {footerData.Sservicetagline || "Truck Dispatch Services"}</li>
              <li><MdContactPhone style={{fontSize:'20px'}}/> {footerData.Pprofilertagline || "Why Choose Us"}</li>
              <li><AiFillProfile style={{fontSize:'20px'}}/> {footerData.Ccontacttagline || "Contact Us"}</li>
            </ul>
          </div>
          <div className="right">
            <div className="rlink">
              <PiIdentificationCardFill style={{fontSize:'20px'}}/>
             {footerData.Ccompanytagline}
            </div>

            <div className="rlink">
              <FaLocationDot style={{fontSize:'20px'}} />
              {footerData.Aaddresstagline || "4445 CORPORATION LN STE 264, Virginia Beach, VA 23462 United States"}
            </div>

            <div className="rlink">
            <FaPhone style={{fontSize:'20px'}}/>
              {footerData.Pphonetagline || "+1(448)-203-5240"}
            </div>

            <a href="https://www.gmail.com" style={{textDecoration:'none', color:'inherit'}}>
              <div className="rlink">
                <MdEmail style={{fontSize:'20px'}}/>
                {footerData.Eemailtagline || "info@aonedispatchers.com"}
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
