import React, { useState } from 'react'
import '../Tadmin/Tadmin.css'
import Anavbar from '../Anavbar/Anavbar'
import { Route, Routes } from 'react-router-dom'


import Aheader from '../Aheader/Aheader';
import Atab from "../Atab/Atab";
import Aslider from "../Aslider/Aslider";
import Acontactform from '../Acontactform/Acontactform';
import Afooter from '../Afooter/Afooter';
import Aimage from "../Aimage/Aimage";



function Tadmin() {


const [loginbool, setloginbool] = useState(false);

const [lres, setlres] = useState('');

const credential = { username:"aonemerchants123", password:"@aonemerchants123"};

const [lform, setlform] = useState({});

const fchange=(e)=>{
setlform({
  ...lform,
  [e.target.name]:e.target.value
});
}

const validate=(e)=>{
  e.preventDefault();

  console.log(lform.username, lform.password)
  if((lform.username===credential.username) && (lform.password===credential.password))
  {
  setloginbool(true);
  }
  else
  {
    setloginbool(false);
    setlres("Invalid Credentials");
    setTimeout(() => {
      setlres('');
    }, 2000);
  }
  e.target.reset();
}


const logout=(v)=>{
setloginbool(v);
}

  return (
   <>
   {

loginbool ? ( <>

   <div className="tadmin">

<div className="anavbar">
<Anavbar p={()=>logout()} />
</div>

<div className="adminbody">

<Routes>

<Route path='Aheader/Aheader' element={<Aheader/>} />
<Route path='Aimage/Aimage' element={<Aimage/>} />
<Route path='Atab/Atab' element={<Atab/>} />
<Route path='Aslider/Aslider' element={<Aslider/>} />
<Route path='Acontactform/Acontactform' element={<Acontactform/>} />
<Route path='Afooter/Afooter' element={<Afooter/>} />
<Route path='' element={<Aheader/>} />

</Routes>

</div>

   </div>
</>
):(

<>

<div className="tadminlogin">

<form method='POST' onSubmit={validate} >
<h2>Login please</h2>
{ lres && <div style={{width:'100%', textAlign:'center', padding:'0.3rem',borderRadius:'10px 10px', backgroundColor:'red', color:'white', fontSize:'21px'}}>
{lres}
</div>}
<input type="text"  placeholder='Username' name="username" onChange={fchange} required/>
<input type="password"  placeholder='Password' name="password" onChange={fchange} required/>
<button type="submit">Login</button>
</form>

</div>

</>
)


}
   </>
  )
}

export default Tadmin
