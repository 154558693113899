import React from 'react'
import '../Anavbar/Anavbar.css'
import { NavLink } from 'react-router-dom'
function Anavbar(props) {
  return (
   <>

  <nav className="navbar navbar-expand-lg" style={{backgroundColor:'white', height:'auto'}}>
  <div className="container-fluid">
    <a className="navbar-brand" href="#"><img src="/tlogo.png"  style={{width:'160px'}} alt="" /></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
     
      <NavLink className={({ isActive }) => (isActive ? 'admin-link' : '')} to="Aheader/Aheader"  style={{textDecoration:'none', color:'inherit'}}>  <li className="nav-item">
          <a className="nav-link active" id="anavbarl" aria-current="page" >Header</a>
        </li></NavLink>
        <NavLink className={({ isActive }) => (isActive ? 'admin-link' : '')} to="Aimage/Aimage"  style={{textDecoration:'none', color:'inherit'}}>  <li className="nav-item">
          <a className="nav-link active" id="anavbarl" aria-current="page" >Images</a>
        </li></NavLink>
        <NavLink className={({ isActive }) => (isActive ? 'admin-link' : '')} to="Atab/Atab"  style={{textDecoration:'none', color:'inherit'}}>  <li className="nav-item">
          <a className="nav-link active" id="anavbarl" aria-current="page" >Tabs</a>
        </li></NavLink>
       
        <NavLink className={({ isActive }) => (isActive ? 'admin-link' : '')} to="Aslider/Aslider"  style={{textDecoration:'none', color:'inherit'}}> <li className="nav-item">
          <a className="nav-link active" id="anavbarl" aria-current="page" >Slider</a>
        </li></NavLink>
        <NavLink className={({ isActive }) => (isActive ? 'admin-link' : '')} to="Acontactform/Acontactform"  style={{textDecoration:'none', color:'inherit'}}> <li className="nav-item">
          <a className="nav-link active"id="anavbarl"  aria-current="page" >View Contacts</a>
        </li></NavLink>
        <NavLink className={({ isActive }) => (isActive ? 'admin-link' : '')} to="Afooter/Afooter"  style={{textDecoration:'none', color:'inherit'}}>  <li className="nav-item">
          <a className="nav-link active" id="anavbarl" aria-current="page" >Footer</a>
        </li></NavLink>
     
      </ul>
      <div >
        <button onClick={()=>{props.p("false")}} className='btn btn-danger'>Logout</button>
      </div>
    </div>
  </div>
</nav>

   </>
  )
}

export default Anavbar
