import React, { useEffect, useState } from 'react'
import '../Dispatch/Dprices.css'
import { FaTruckMoving } from "react-icons/fa";
import { ImPower } from "react-icons/im";
import { TfiDownload } from "react-icons/tfi";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { BsArrowUpRight } from "react-icons/bs";
import Footer from '../Footer/Footer';



function Hprices() {



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const showsection=()=>{
    
  }
  
  
  
  const [tab, settab] = useState([]);
  
  const fetchtabsdmain=async()=>{
  
  
   const responce =  await fetch(`${process.env.REACT_APP_API_URL}/dispatchtab`,{ method:'GET'});
  
   if(responce.ok)
   {
  const data =  await responce.json();
  settab(data);
   }
   else
   {
  console.log("Problem in fetching");
   }
  }
  
  
  
  const [tabch, settabch] = useState([]);
  
  const  fetchtabcarhauler=async()=>{
  
  
   const responce =  await fetch(`${process.env.REACT_APP_API_URL}/carhaulertab`,{ method:'GET'});
  
   if(responce.ok)
   {
  const data =  await responce.json();
  settabch(data);
   }
   else
   {
  console.log("Problem in fetching");
   }
  }
  
  
  
  const [tablb, settablb] = useState([]);
  
  const  fetchtablogbook=async()=>{
  
  
   const responce =  await fetch(`${process.env.REACT_APP_API_URL}/logbooktab`,{ method:'GET'});
  
   if(responce.ok)
   {
  const data =  await responce.json();
  settablb(data);
   }
   else
   {
  console.log("Problem in fetching");
   }
  }
  
  
  
  const [tabifta, settabifta] = useState([]);
  
  const   fetchtabifta=async()=>{
  
  
   const responce =  await fetch(`${process.env.REACT_APP_API_URL}/iftatab`,{ method:'GET'});
  
   if(responce.ok)
   {
  const data =  await responce.json();
  settabifta(data);
   }
   else
   {
  console.log("Problem in fetching");
   }
  }
  
  
  
  
  const [tabaccounting, settabaccounting] = useState([]);
  
  const   fetchtabaccounting=async()=>{
  
  
   const responce =  await fetch(`${process.env.REACT_APP_API_URL}/accountingtab`,{ method:'GET'});
  
   if(responce.ok)
   {
  const data =  await responce.json();
  settabaccounting(data);
   }
   else
   {
  console.log("Problem in fetching");
   }
  }
  
  
  
  const [tabadmin, settabadmin] = useState([]);
  
  const    fetchtabadministration=async()=>{
  
  
   const responce =  await fetch(`${process.env.REACT_APP_API_URL}/administrationtab`,{ method:'GET'});
  
   if(responce.ok)
   {
  const data =  await responce.json();
  settabadmin(data);
   }
   else
   {
  console.log("Problem in fetching");
   }
  }
  
  
  
  
  const [tabhd, settabhd] = useState([]);
  
  const   fetchtabhiringdriver=async()=>{
  
  
   const responce =  await fetch(`${process.env.REACT_APP_API_URL}/hiringdrivertab`,{ method:'GET'});
  
   if(responce.ok)
   {
  const data =  await responce.json();
  settabhd(data);
   }
   else
   {
  console.log("Problem in fetching");
   }
  }
  
  
  
  const [tabmc, settabmc] = useState([]);
  
  const   fetchtabsetupmc=async()=>{
  
  
   const responce =  await fetch(`${process.env.REACT_APP_API_URL}/setupmctab`,{ method:'GET'});
  
   if(responce.ok)
   {
  const data =  await responce.json();
  settabmc(data);
   }
   else
   {
  console.log("Problem in fetching");
   }
  }
  
  
  useEffect(()=>{
    fetchtabsdmain();
    fetchtabcarhauler();
    fetchtablogbook();
    fetchtabifta();
    fetchtabaccounting();
    fetchtabadministration();
    fetchtabhiringdriver();
    fetchtabsetupmc();
  }, [])
  
  

  return (
    <>
    

    <div className="dmain">
    
  


<div className="r2">


<div className="left">
  <div className="fleetsize">
  Fleet Size
  </div>
  <div className="detail">
  Select the trucks amount to get a more detailed offer
  </div>
<br/>
<div className="buttons">
  <button>1</button>
  <button>2-5</button>
  <button>6-10</button>
  <button>21-50</button>
  <button>51-100</button>
  <button>101 +</button>
</div>
</div>


<div className="vline">
  
</div>

<div className="right">
<div className="fleetsize">
Truck Type
  </div>
  <div className="detail">
  Select the type of your truck for additinal offers
  </div>
<br/>
<div className="buttons">
  <button> <FaTruckMoving style={{fontSize:'25px',color:'black'}}/> DryVan</button>
  <button ><FaTruckMoving style={{fontSize:'25px',color:'black'}}/> Reefer</button>
  <button><FaTruckMoving style={{fontSize:'25px',color:'black'}}/> FI/ST Deck</button>
  <button><ImPower style={{fontSize:'20px',color:'black'}} /> Power Only</button>
</div>
</div>

</div>
    <br/>








<div className="r3">

<h2 style={{display:'flex',fontWeight:'bold',fontSize:'45px', justifyContent:'flex-start', width:'80%'}}>Dispatch</h2>
{tab.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button">
               
              </div>
            </div>
          </div>
        </>
      ))}


<br/><br/><br/>



<h2 style={{display:'flex',fontWeight:'bold',fontSize:'45px', justifyContent:'flex-start', width:'80%'}}>Car Hauler</h2>
{tabch.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button">
               
              </div>
            </div>
          </div>
        </>
      ))}








<br/><br/><br/>
<h2 style={{display:'flex',fontWeight:'bold',fontSize:'45px', justifyContent:'flex-start', width:'80%'}}>Logbook</h2>

{tablb.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button">
               
              </div>
            </div>
          </div>
        </>
      ))}






<br/><br/><br/>
<h2 style={{display:'flex',fontWeight:'bold',fontSize:'45px', justifyContent:'flex-start', width:'80%'}}>IFTA</h2>

{tabifta.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button">
               
              </div>
            </div>
          </div>
        </>
      ))}







<br/><br/><br/>
<h2 style={{display:'flex',fontWeight:'bold',fontSize:'45px', justifyContent:'flex-start', width:'80%'}}>Accounting</h2>

{tabaccounting.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button">
               
              </div>
            </div>
          </div>
        </>
      ))}


<br/><br/><br/>
<h2 style={{display:'flex',fontWeight:'bold',fontSize:'45px', justifyContent:'flex-start', width:'80%'}}>Administration</h2>

{tabadmin.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button">
               
              </div>
            </div>
          </div>
        </>
      ))}



<br/><br/><br/>
<h2 style={{display:'flex',fontWeight:'bold',fontSize:'45px', justifyContent:'flex-start', width:'80%'}}>Hiring drivers</h2>

{tabhd.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button">
               
              </div>
            </div>
          </div>
        </>
      ))}



<br/><br/><br/>
<h2 style={{display:'flex',fontWeight:'bold',fontSize:'45px', justifyContent:'flex-start', width:'80%'}}>Setup MC</h2>
{tabmc.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button">
              </div>
            </div>
          </div>
        </>
      ))}




</div>





<br/>

<Footer/>

    </div> 

   
    
    
    </>
  )
}

export default Hprices
