import React, { useEffect, useState } from 'react'
import '../Mnavbar/Mnavbar.css'
import { FaCartShopping } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';


function Mnavbar() {

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    setExpanded(false);
  };





  const [logo , setlogo] = useState([]);

  const fetchlogo=async()=>{

    const responce  = await fetch(`${process.env.REACT_APP_API_URL}/fetchlogo`,{method:'GET'})

    if(responce.ok)
    {
const data = await responce.json();
setlogo(data);
    }
    else
    {
console.log('Logo Not fetch properly');
    }
  }

  useEffect(()=>{
    fetchlogo();
      },[])



  return (
   <>

   <nav className="navbar navbar-expand-lg" style={{backgroundColor:'white',height:'auto', color:'white' }}>
  <div className="container-fluid">
    <a className="navbar-brand" >
    {/* { logo.map( val =>(

<NavLink to="Dispatch/Dmain" style={{textDecoration:'none', color:'inherit'}} >
  <div className='imgdiv'>
  <img src={`${process.env.REACT_APP_API_URL}/${val.imagePath}`} style={{width:'100px'}} />
  </div>
  </NavLink>

    )  )
} */}

<NavLink to="Dispatch/Dmain" style={{textDecoration:'none', color:'inherit'}} >
  <div className='imgdiv'>
  <img src="/tlogo.png"style={{width:'100px'}} />
  </div>
</NavLink>



      </a>

    <div className="cart" style={{marginLeft:'auto',position:'relative', right:'1rem'}}>
      <FaCartShopping style={{fontSize:'21px', cursor:'pointer' , color:'#0e4154'}}/>
      </div>
    <button className="navbar-toggler" onClick={handleToggle} style={{backgroundColor:'white'}} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent" >
      <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{color:'#0e4154', display:'flex', justifyContent:'center'}}>
      <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dispatch
          </a>
          <ul className="dropdown-menu">
           <NavLink onClick={handleLinkClick} to="Dispatch/Dmain" style={{textDecoration:'none', color:'inherit'}}><li><a className="dropdown-item" >Main</a></li></NavLink> 
           <NavLink onClick={handleLinkClick} to="Dispatch/Dservices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Services</a></li></NavLink>
           <NavLink onClick={handleLinkClick} to="Dispatch/Dwhyus" style={{textDecoration:'none', color:'inherit'}}><li><a className="dropdown-item" >Why us</a></li></NavLink>
           <NavLink onClick={handleLinkClick} to="Dispatch/Dcontacts" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Contacts </a></li></NavLink>
           <NavLink onClick={handleLinkClick} to="Dispatch/Dprices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
           Safety
          </a>
          <ul className="dropdown-menu">
          <NavLink onClick={handleLinkClick} to="Safety/Smain" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Main</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Safety/Sservices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Services</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Safety/Swhyus" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Why us</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Safety/Scontacts" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Contacts </a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Safety/Sprices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Logbooks
          </a>
          <ul className="dropdown-menu">
          <NavLink onClick={handleLinkClick} to="Logbook/Lmain" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Main</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Logbook/Lservices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Services</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Logbook/Lwhyus" style={{textDecoration:'none', color:'inherit'}}><li><a className="dropdown-item" >Why us</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Logbook/Lcontacts" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Contacts </a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Logbook/Lprices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            IFTA
          </a>
          <ul className="dropdown-menu">
          <NavLink  onClick={handleLinkClick} to="Ifta/Imain" style={{textDecoration:'none', color:'inherit'}}>   <li><a className="dropdown-item" >Main</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Ifta/Iservices" style={{textDecoration:'none', color:'inherit'}}>  <li><a className="dropdown-item" >Services</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Ifta/Iwhyus" style={{textDecoration:'none', color:'inherit'}}>  <li><a className="dropdown-item" >Why us</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Ifta/Icontacts" style={{textDecoration:'none', color:'inherit'}}>  <li><a className="dropdown-item" >Contacts </a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Ifta/Iprices" style={{textDecoration:'none', color:'inherit'}}>  <li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Accounting
          </a>
          <ul className="dropdown-menu">
          <NavLink onClick={handleLinkClick} to="Accounting/Amain" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Main</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Accounting/Aservices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Services</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Accounting/Acontacts" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Contacts </a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Accounting/Aprices" style={{textDecoration:'none', color:'inherit'}}><li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Administration
          </a>
          <ul className="dropdown-menu">
          <NavLink onClick={handleLinkClick} to="Administration/Admain" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Main</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Administration/Adservices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Services</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Administration/Adwhyus" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Why us</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Administration/Adcontacts" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Contacts </a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Administration/Adprices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Hiring Drivers
          </a>
          <ul className="dropdown-menu">
          <NavLink onClick={handleLinkClick} to="Hiringdrivers/Hmain" style={{textDecoration:'none', color:'inherit'}}>  <li><a className="dropdown-item" >Main</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Hiringdrivers/Happlication" style={{textDecoration:'none', color:'inherit'}}>  <li><a className="dropdown-item" >Application</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Hiringdrivers/Hneeddrivers" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Need Drivers</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Hiringdrivers/Hprices" style={{textDecoration:'none', color:'inherit'}}>  <li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            TMS
          </a>
          <ul className="dropdown-menu">
          <NavLink onClick={handleLinkClick} to="Tms/Tmain" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Main</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Tms/Tcontracts" style={{textDecoration:'none', color:'inherit'}}>  <li><a className="dropdown-item" >Contracts</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Tms/Tprices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Setup MC
          </a>
          <ul className="dropdown-menu">
          <NavLink onClick={handleLinkClick} to="Mc/Mmain" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Main</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Mc/Mservices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Services</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Mc/Mwhyus" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Why us</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Mc/Mcontacts" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Contacts </a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Mc/Mprices" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color:'#0e4154'}}  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Car hauler
          </a>
          <ul className="dropdown-menu">
          <NavLink onClick={handleLinkClick} to="Carhauler/Cmain" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Main</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Carhauler/Ccontacts" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Contact Us</a></li></NavLink>
          <NavLink onClick={handleLinkClick} to="Carhauler/Ccontacts" style={{textDecoration:'none', color:'inherit'}}> <li><a className="dropdown-item" >Prices</a></li></NavLink>
          </ul>
        </li>
       
      </ul>


     
    
    </div>
  </div>
</nav>
   
   
   
   
   
   </>
  )
}

export default Mnavbar
