import Layout from "./Layout/Layout";
import Dmain from "./Dispatch/Dmain";
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import Dservices from "./Dispatch/Dservices";
import Dwhyus from "./Dispatch/Dwhyus";
import Dcontacts from "./Dispatch/Dcontacts";
import Dprices from './Dispatch/Dprices';
import Smain from "./Safety/Smain";
import Sservices from "./Safety/Sservices";
import Swhyus from "./Safety/Swhyus";
import Scontacts from "./Safety/Scontacts";
import Sprices from './Safety/Sprices';
import Lmain from "./Logbook/Lmain";
import Lservices from './Logbook/Lservices';
import Lwhyus from './Logbook/Lwhyus';
import Lcontacts from './Logbook/Lcontacts';
import Lprices from './Logbook/Lprices';
import Imain from './Ifta/Imain';
import Iservices from './Ifta/Iservices';
import Iwhyus from './Ifta/Iwhyus';
import Icontacts from './Ifta/Icontacts';
import Iprices from './Ifta/Iprices';
import Amain from './Accounting/Amain';
import Aservices from './Accounting/Aservices';
import Acontacts from './Accounting/Acontacts';
import Aprices from './Accounting/Aprices';
import Admain from './Administration/Admain';
import Adservices from './Administration/Adservices';
import Adwhyus from './Administration/Adwhyus';
import Adcontacts from './Administration/Adcontacts';
import Adprices from './Administration/Adprices';
import Hmain from './Hiringdrivers/Hmain';
import Happlication from './Hiringdrivers/Happlication';
import Hneeddrivers from './Hiringdrivers/Hneeddrivers';
import Hprices from './Hiringdrivers/Hprices';
import Tmain from './Tms/Tmain';
import Tcontracts from './Tms/Tcontracts';
import Tprices from './Tms/Tprices';
import Mmain from './Mc/Mmain';
import Mservices from './Mc/Mservices';
import Mwhyus from './Mc/Mwhyus';
import Mcontacts from './Mc/Mcontacts';
import Mprices from './Mc/Mprices';
import Cmain from './Carhauler/Cmain';
import Ccontacts from './Carhauler/Ccontacts';
import Cprices from './Carhauler/Cprices';
import Tadmin from "./Tadmin/Tadmin";

import Aheader from './Aheader/Aheader';
import Atab from "./Atab/Atab";
import Aslider from "./Aslider/Aslider";
import Acontactform from './Acontactform/Acontactform';
import Afooter from './Afooter/Afooter';
import Aimage from "./Aimage/Aimage";


function App() {
  return (
   <>

<BrowserRouter>
<Routes>

<Route path="" element={<Layout/>}>
  <Route path='Dispatch/Dmain' element={<Dmain/>} />
  <Route path='Dispatch/Dservices' element={<Dservices/>} />
  <Route path='Dispatch/Dwhyus' element={<Dwhyus/>} />
  <Route path='Dispatch/Dcontacts' element={<Dcontacts/>} />
  <Route path='Dispatch/Dprices' element={<Dprices/>} />
  <Route path='Safety/Smain' element={<Smain/>} />
  <Route path='Safety/Sservices' element={<Sservices/>} />
  <Route path='Safety/Swhyus' element={<Swhyus/>} />
  <Route path='Safety/Scontacts' element={<Scontacts/>} />
  <Route path='Safety/Sprices' element={<Sprices/>} />
  <Route path='Logbook/Lmain' element={<Lmain/>} />
  <Route path='Logbook/Lservices' element={<Lservices/>} />
  <Route path='Logbook/Lwhyus' element={<Lwhyus/>} />
  <Route path='Logbook/Lcontacts' element={<Lcontacts/>} />
  <Route path='Logbook/Lprices' element={<Lprices/>} />
  <Route path='Ifta/Imain' element={<Imain/>} />
  <Route path='Ifta/Iservices' element={<Iservices/>} />
  <Route path='Ifta/Iwhyus' element={<Iwhyus/>} />
  <Route path='Ifta/Icontacts' element={<Icontacts/>} />
  <Route path='Ifta/Iprices' element={<Iprices/>} />
  <Route path='Accounting/Amain' element={<Amain/>} />
  <Route path='Accounting/Aservices' element={<Aservices/>} />
  <Route path='Accounting/Acontacts' element={<Acontacts/>} />
  <Route path='Accounting/Aprices' element={<Aprices/>} />
  <Route path='Administration/Admain' element={<Admain/>} />
  <Route path='Administration/Adservices' element={<Adservices/>} />
  <Route path='Administration/Adwhyus' element={<Adwhyus/>} />
  <Route path='Administration/Adcontacts' element={<Adcontacts/>} />
  <Route path='Administration/Adprices' element={<Adprices/>} />
  <Route path='Hiringdrivers/Hmain' element={<Hmain/>} />
  <Route path='Hiringdrivers/Happlication' element={<Happlication/>} />
  <Route path='Hiringdrivers/Hneeddrivers' element={<Hneeddrivers/>} />
  <Route path='Hiringdrivers/Hprices' element={<Hprices/>} />
  <Route path='Tms/Tmain' element={<Tmain/>} />
  <Route path='Tms/Tcontracts' element={<Tcontracts/>} />
  <Route path='Tms/Tprices' element={<Tprices/>} />
  <Route path='Mc/Mmain' element={<Mmain/>} />
  <Route path='Mc/Mservices' element={<Mservices/>} />
  <Route path='Mc/Mwhyus' element={<Mwhyus/>} />
  <Route path='Mc/Mcontacts' element={<Mcontacts/>} />
  <Route path='Mc/Mprices' element={<Mprices/>} />
  <Route path='Carhauler/Cmain' element={<Cmain/>} />
  <Route path='Carhauler/Ccontacts' element={<Ccontacts/>} />
  <Route path='Carhauler/Cprices' element={<Cprices/>} />
  <Route path="" element={<Dmain/>} />
</Route>


<Route path="/admin" element={<Tadmin/>}>

<Route path='Aheader/Aheader' element={<Aheader/>} />
<Route path='Aimage/Aimage' element={<Aimage/>} />
<Route path='Atab/Atab' element={<Atab/>} />
<Route path='Aslider/Aslider' element={<Aslider/>} />
<Route path='Acontactform/Acontactform' element={<Acontactform/>} />
<Route path='Afooter/Afooter' element={<Afooter/>} />
<Route path='' element={<Aheader/>} />

</Route>


<Route path='/Dispatch/Dmain' element={<Layout/>} />



  
</Routes>
</BrowserRouter>

   </>
  );
}

export default App;
