import React, { useState } from 'react'
import '../Aslider/Aslider.css'

function Aslider() {



const [ansform , setansform] = useState({});

const [ansres , setansres] = useState('');

const [stdform , setstdform] = useState({});

const [stdres , setstdres] = useState('');


  const anschange=(e)=>{
    setansform({
      ...ansform,
      [e.target.name]: e.target.value
    }); 
  }



  const stdchange=(e)=>{
    setstdform({
      ...stdform,
      [e.target.name]: e.target.value,
    });
  }

  const addnewslidersubmit=async(e)=>{
e.preventDefault();


const responce = await fetch(`${process.env.REACT_APP_API_URL}/addnewslider`, {
  method:'POST',
  body:JSON.stringify(ansform),
  headers:{'Content-Type':'application/json'}
})

if(responce.ok)
{
const data =  await responce.json();
setansres(data.message);
setTimeout(() => {
  setansres('');
}, 2000);
}
else
{
  console.log('slider is NOT successfuly added')
}

  e.target.reset();

  }

//------------------------------------------------------------------------------------------------

const sliderdeletesubmit=async(e)=>{
  e.preventDefault();


const responce = await fetch(`${process.env.REACT_APP_API_URL}/deleteslider`, {
  method:'POST',
  body:JSON.stringify(stdform),
  headers:{'Content-Type':'application/json'}
})

if(responce.ok)
{
const data =  await responce.json();
setstdres(data.message);
setTimeout(() => {
  setstdres('');
}, 2000);
}
else
{
  console.log('slider is NOT successfuly deleted')
}

  e.target.reset();



 }


  return (
    <>
    
    
  
    <div className="admintabs">
<br/>

<h2>Add New Slider</h2>

{ ansres && <div style={{width:'100%', textAlign:'center', padding:'0.5rem', backgroundColor:'orange', color:'white', fontSize:'21px'}}>

{ansres}

</div>}

<form method='POST' onSubmit={addnewslidersubmit}>

  <input type="text" placeholder='Enter slider Title' name="stitle" onChange={anschange} required/>

  <input type="text" placeholder='Enter slider description' name="sdescription" onChange={anschange} required/>

  <input type="text" placeholder='Enter slider price' name="sprice" onChange={anschange} required/>

  <select required name="scategory" onChange={anschange}>
  <option>Choose Category</option>
  <option >dispatch</option>
  <option >safety</option>
  <option >logbook</option>
  <option >IFTA</option>
  <option >Accounting</option>
  <option >Administration</option>
  <option >Hiring Drivers</option>
  <option >TMS</option>
  <option >Setup MC</option>
  <option >Car Hauler</option>
</select>

<button type="submit">Add new slider</button>

</form>


<h2>Remove Slider</h2>

{ stdres && <div style={{width:'100%', textAlign:'center', padding:'0.5rem', backgroundColor:'orange', color:'white', fontSize:'21px'}}>

{stdres}

</div>}

<form method='POST' onSubmit={sliderdeletesubmit}>

  <input type="text" placeholder='Enter Slider Title' name="stitle" onChange={stdchange}  required/>

  
  <select required name="scategory" onChange={stdchange}>
  <option>Choose Category</option>
  <option >dispatch</option>
  <option >safety</option>
  <option >logbook</option>
  <option >IFTA</option>
  <option >Accounting</option>
  <option >Administration</option>
  <option >Hiring Drivers</option>
  <option >TMS</option>
  <option >Setup MC</option>
  <option >Car Hauler</option>
</select>

<button type="submit">Remove slider</button>

</form>

   </div>
    
    </>
  )
}

export default Aslider
