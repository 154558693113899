import React, { useEffect, useState } from 'react'
import '../Acontactform/Acontactform.css'
import { CiSquareRemove } from "react-icons/ci";




function Acontactform() {


  const [tab, settab] = useState([]);
  const fetchcform=async()=>{

    const responce =  await fetch(`${process.env.REACT_APP_API_URL}/fetchcontactform`,{ method:'GET'});

    if(responce.ok)
    {
   const data =  await responce.json();
   settab(data);
    }
    else
    {
   console.log("Problem in fetching");
    }

  }

useEffect(()=>{

fetchcform();

},[])


const [sendpopup, setsendpopup] = useState('');
const deleterequest= async(rid)=>{

  const c = window.confirm('Are you shure to Delete it ?');

  if(!c)
  {
    return ;
  }

 const responce =  await fetch(`${process.env.REACT_APP_API_URL}/deleteformrequest`,{
    method:'POST',
    body:JSON.stringify({rid}),
    headers:{'Content-Type':'application/json'}
  })

if(responce.ok)
{
const data =  await responce.json();
fetchcform();
}
else
{
  console.log('form NOT deleted ')
}

}


  return (
   <>
   
   
<div className="cformmain">



<div class="customer-portal-container">
        <h1 class="customer-portal-title">Customer Form Requests</h1>
{
  tab.map( val =>(<>

        <div class="customer-contact-card" key={val._id}>
          <div>
            <h4 class="customer-contact-name">Name: {val.Cname}</h4>
            <p class="customer-contact-email">Email: {val.Cemail}</p>
            <p class="customer-contact-phone">Phone: {val.Cphone}</p>
            <p class="customer-contact-message">Message: {val.Cmessage}</p>
            </div>
            <div style={{cursor:'pointer'}} onClick={()=>{deleterequest(val._id)}} >
            <CiSquareRemove style={{fontSize:'40px', color:'red'}} />
            </div>
            {sendpopup && <div className="sendpopup" style={{backgroundColor:'#fa9720',fontWeight:'bold',fontSize:'21px',borderRadius:'10px 10px', color:'white', padding:'1rem'}}>
    {sendpopup}
 </div>}
        </div>  
        </>
  ))
}
    </div>




</div>
   
   
   
   
   </>
  )
}

export default Acontactform
