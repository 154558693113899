import React, { useEffect, useState } from 'react'
import '../Aheader/Aheader.css'
import { MdDeleteForever } from "react-icons/md";


function Aheader() {


  const [file, setFile] = useState(null);

  const handlelogochange=(e)=>{
    setFile(e.target.files[0]);
  }

  const [logores, setlogores] = useState('');

  const logosubmit= async(e)=>{

    e.preventDefault();
    const formData = new FormData();
    formData.append('logo', file);


   const responce =  await fetch(`${process.env.REACT_APP_API_URL}/uploadlogo`,{
      method:'POST',
      body:formData
    });

    if(responce.ok)
    {
const data = await responce.json();
setlogores(data.message);
setTimeout(() => {
  setlogores('');
}, 2000);
fetchlogo();
    }
    else
    {
      console.log('logo is NOT uploaded successfuly');
    }
  }


  const [logo , setlogo] = useState([]);

  const fetchlogo=async()=>{

    const responce  = await fetch(`${process.env.REACT_APP_API_URL}/fetchlogo`,{method:'GET'})

    if(responce.ok)
    {
const data = await responce.json();
setlogo(data);
    }
    else
    {
console.log('Logo Not fetch properly');
    }
  }


  useEffect(()=>{
fetchlogo();
  },[])


const deletelogo=async(lid)=>{

  const confirmed = window.confirm('Are you sure you want to remove the logo?');

  if (!confirmed) {
    return; // Exit the function if the user cancels the confirmation
  }

  const responce =  await fetch(`${process.env.REACT_APP_API_URL}/deletelogo`,{
    method:'POST',
    body:JSON.stringify({lid}),
    headers:{'Content-Type':'application/json'}
  });

  if(responce.ok)
  {
const data = await responce.json();
setlogores(data.message);
setTimeout(() => {
setlogores('');
}, 2000);
fetchlogo();
  }
  else
  {
    console.log('logo is NOT deleted successfuly');
  }
}




const [hnres, sethnres] = useState('');
const [hnform, sethnform] = useState({});
const hnchange=(e)=>{

sethnform({
  ...hnform,
  [e.target.name]:e.target.value
});
}

const hnsubmit= async(e)=>{
  e.preventDefault();

  const responce =  await fetch(`${process.env.REACT_APP_API_URL}/uploadhn`,{
    method:'POST',
    body:JSON.stringify(hnform),
    headers:{'Content-Type':'application/json'}
  });

  if(responce.ok)
  {
    const data = await responce.json();
    sethnres(data.message);
  setTimeout(() => {
    sethnres('');
  }, 2000);
  }
  else
  {
    console.log('logo is NOT uploaded successfuly');
  }
  e.target.reset();
}


  return (
 <>
 
 

 <div className="admintabs">
<br/>


<h2>Add Logo</h2>

{ logores && <div style={{width:'100%', textAlign:'center', padding:'0.5rem', backgroundColor:'orange', color:'white', fontSize:'21px'}}>

{logores}

</div>}

<form method='POST' onSubmit={logosubmit}>
<input type="file" accept="image/*"   onChange={handlelogochange} required/>
<button type="submit">Upload</button>

{ logo.map( val =>(<>
  <div className='imgdiv'>
  <img src={`${process.env.REACT_APP_API_URL}/${val.imagePath}`} style={{width:'100px'}} />
  </div>
<div onClick={()=>{deletelogo(val._id)}} style={{cursor:'pointer'}}>
<MdDeleteForever style={{fontSize:'40px', color:'red'}} />
</div>
</>
  ))
}

</form>



<h2>Add Phone Number</h2>

{ hnres && <div style={{width:'100%', textAlign:'center', padding:'0.5rem', backgroundColor:'orange', color:'white', fontSize:'21px'}}>

{hnres}

</div>}

<form method='POST' onSubmit={hnsubmit} >
<input type="text" placeholder='Format +1(540)-618-2050' name="headernumber" onChange={hnchange} required/>
<button type="submit">Save</button>
</form>




   </div>
 
 </>
  )
}

export default Aheader
