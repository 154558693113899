import React, { useEffect, useState } from 'react'
import '../Dispatch/Dmain.css'
import { FaTruckMoving } from "react-icons/fa";
import { ImPower } from "react-icons/im";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TfiDownload } from "react-icons/tfi";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { BsArrowUpRight } from "react-icons/bs";
import Footer from '../Footer/Footer';




//6Le5QxgqAAAAABMDBKo6APEVmQSXjp8oIhDu9s80

function Amain() {





useEffect(() => {
  window.scrollTo(0, 0);
}, []);





  let currentIndex=0;

  const slideshow=()=>{
  
    const slider = document.querySelector('.slider');
  const totalslides = document.querySelectorAll('.box');
  
  console.log('total nodes are ',totalslides);
  
   // Correctly wrap the currentIndex
   if (currentIndex < 0) {
    currentIndex = totalslides.length - 1;
  }
  if (currentIndex >= totalslides.length) {
    currentIndex = 0;
  }
  
  // Adjust the transform property to show the correct slide
  slider.style.transform = `translateX(-${currentIndex * 22}rem)`;
    
  }
  
  const handleleft=()=>{
    currentIndex=currentIndex-1;
    slideshow();
  }


  const handleright=()=>{
    currentIndex=currentIndex+1;
    slideshow();
  }












  

const [cform , setcform] = useState({});
const [sendpopup, setsendpopup] = useState('');

  const formchange=(e)=>{

    setcform({
      ...cform,
      [e.target.name]: e.target.value
    });
  }

//-------------------------------------------------------------------------
  const handleformsubmit=async(e)=>{

    e.preventDefault();

const responce = await fetch(`${process.env.REACT_APP_API_URL}/cformsubmit`, {
  method:'POST',
  body:JSON.stringify(cform),
  headers:{'Content-Type':'application/json'}
})

if(responce.ok)
{
const data =  await responce.json();
setsendpopup(data.message);
setTimeout(() => {
  setsendpopup('');
}, 2000);
}
else
{
  console.log('form NOT successfuly submit')
}

  e.target.reset();

  }
  

const [tab, settab] = useState([]);

const fetchtabs=async()=>{


 const responce =  await fetch(`${process.env.REACT_APP_API_URL}/accountingtab`,{ method:'GET'});

 if(responce.ok)
 {
const data =  await responce.json();
settab(data);
 }
 else
 {
console.log("Problem in fetching");
 }
}
//--------------------------------------------------------------------------------------------

const [slider , setslider] = useState([]);
const  fetchslider =async()=>{


  const responce =  await fetch(`${process.env.REACT_APP_API_URL}/accountingslider`,{ method:'GET'});
 
  if(responce.ok)
  {
 const data =  await responce.json();
 setslider(data);
  }
  else
  {
 console.log("Problem in fetching");
  }
 }

 const [bgimg , setbgimg] = useState([]);
 const fetchbgimg= async()=>{

  const responce =  await fetch(`${process.env.REACT_APP_API_URL}/accountingbgimg`,{ method:'GET'});
 
  if(responce.ok)
  {
 const data =  await responce.json();
 setbgimg(data);
  }
  else
  {
 console.log("Problem in fetching");
  }

 }


  useEffect(()=>{
    fetchtabs();
    fetchslider();
    fetchbgimg();
  }, [])

  const [openIndex, setOpenIndex] = useState(null);

  const toggleDropdown = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };






  
  return (
   <>
  
   <div className="dmain">
    
    {
      bgimg.map( image =>(
    <div className="topimg" key={image._id}> 
<img src={`${process.env.REACT_APP_API_URL}${image.BimagePath}`} />
    </div>
       ) )
}

<div className="r2">


<div className="left">
  <div className="fleetsize">
  Fleet Size
  </div>
  <div className="detail">
  Select the trucks amount to get a more detailed offer
  </div>
<br/>
<div className="buttons">
  <button>1</button>
  <button>2-5</button>
  <button>6-10</button>
  <button>21-50</button>
  <button>51-100</button>
  <button>101 +</button>
</div>
</div>


<div className="vline">
  
</div>

<div className="right">
<div className="fleetsize">
Truck Type
  </div>
  <div className="detail">
  Select the type of your truck for additinal offers
  </div>
<br/>
<div className="buttons">
  <button> <FaTruckMoving style={{fontSize:'25px',color:'black'}}/> DryVan</button>
  <button ><FaTruckMoving style={{fontSize:'25px',color:'black'}}/> Reefer</button>
  <button><FaTruckMoving style={{fontSize:'25px',color:'black'}}/> FI/ST Deck</button>
  <button><ImPower style={{fontSize:'20px',color:'black'}} /> Power Only</button>
</div>
</div>

</div>
    <br/>






    <div className="r3">

    {tab.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button" onClick={() => toggleDropdown(index)}>
                <RiArrowDropDownLine id="dropicon" />
              </div>
            </div>
          </div>

          {openIndex === index && (
            <div className="sectiondropdown">
              <div className="left">
                <div className="a1">
                  {value.Ttabtitle}
                </div>
                <div className="a2">
                  {value.Ttabpara1}
                </div>
                <div className="a3">
                  {value.Ttabpara2}
                </div>
                <div className="a4">
                  <TfiDownload style={{ fontSize: '40px', color: 'red' }} />
                  Download Offer
                </div>
                <div className="a5">
                  <form method='POST' onSubmit={handleformsubmit}>
                    <h3>Want to talk with us?</h3>
                    {sendpopup && <div className="sendpopup" style={{backgroundColor:'#fa9720',fontWeight:'bold',fontSize:'21px',borderRadius:'10px 10px', color:'white', padding:'1rem'}}>
                       {sendpopup}
                    </div>}
                    <label>Name</label>
                    <input type="text" placeholder='Your Name' name="name" onChange={formchange} required />
                    <label>Phone</label>
                    <input type="number" placeholder='Your Phone' name="phone" onChange={formchange} required />
                    <label>Email</label>
                    <input type="email" placeholder='Your Email' name="email" onChange={formchange} required />
                    <label>Message</label>
                    <input type="text" placeholder='Your Message' name="message" onChange={formchange} required />
                    <button type="submit">Send</button>
                  </form>
                </div>
              </div>
              <div className="right">
                <div className="b1">
                  <div className="n">
                    {value.Ttabprice}%
                  </div>
                  <div className="b" onClick={() => toggleDropdown(index)}>
                    <FaChevronUp id="upicon" />
                  </div>
                </div>
                <div className="b2">
                <div dangerouslySetInnerHTML={{ __html: value.Ttabvideo }} />
                </div>
              </div>
            </div>
          )}
        </>
      ))}
</div>


<br/><br/>



<div className="r4">
<div className="upper">
<div style={{fontSize:'40px' ,fontWeight:'bold'}}>Other Services</div>
<div className='rlbutton'>
<FaChevronLeft style={{cursor:'pointer'}} onClick={handleleft} />
<FaChevronRight style={{cursor:'pointer'}} onClick={handleright} />
</div>
</div>
<div className="lower">
<div className="slider">
{
  slider.map( val =>(

<div className="box" key={val._id} >
    <div className="b1">
<div className="upper">
<h2>{val.Sstitle}</h2>
  <p>{val.Ssdescription}</p>
</div>
<div className="lower">

<div className="left">{val.Ssprice} </div>
<div className="right"><BsArrowUpRight style={{fontSize:'35px'}} /></div>
</div>
    </div>
  </div>

  ))
}
</div>
</div>
</div>




<br/>



<div className="r5">


<h2>The Truck Dispatching Company to Keep Your Business Moving</h2>
<p>As a truck driver or logistics business owner, you understand the importance of reliable dispatching services. At AOne Dispatchers, a project of AOne Merchants LLC, we are committed to providing the support you need to keep your trucking operation running smoothly.

Located in Virginia Beach, Virginia, we offer our dispatching services to trucking companies across the nation. We understand that your business plays a vital role in our society and economy, and we are here to help you provide top-quality logistics services.

Contact us by email or phone to learn more about how we can support your business. Our team is dedicated to providing you with the information and quotes you need to get started. At AOne Dispatchers, we are passionate about helping you succeed.</p>
  



<h2>What Our Truck Accounting Services Offer:</h2>
<br/>
  <b>What tests are required under DOT guidelines?</b>
  Pre-employment, Reasonable Suspicion, Random, Post Accident, Return to Duty and Follow-up.

 
<br/><br/>
<b>My driver had his personal vehicle license suspended, but his CDL Class A is still valid. Can he still drive for me?</b>
Yes. However, the driver is prohibited from operating a vehicle between 10,001 and 26,000 pounds. During the suspension, the driver may not operate a “smaller” CMV (or box truck). The driver’s Class A CDL only allows him or her to operate a vehicle that is 26,001 pounds and over with a trailer in excess of 10,001 pounds.

 
<br/><br/>
<b>What is included in a safety plan?</b>
✓ Triggers<br/>

✓ Support system<br/>

✓ Professional support<br/>

✓ Acknowledgment

 
<br/><br/>
<b>How will a claim affect my company?</b>
If you have a claim, it may increase the cost of your premium when your policy is due for renewal. Your No Claim Bonus may also be affected; this depends on the level of No Claim Bonus Protection you select.<br/>

 <br/>
<b>Who qualifies for an IFTA license?</b>
A vehicle used or designed to transport people or property is a qualified motor vehicle if it:

Has three or more axles; or

Has two axles and a gross vehicle or registered gross vehicle weight of more than 26,000 pounds or 11,797 kilograms; or Is used in a combination that has a combined or registered gross vehicle weight of more than 26,000 pounds or 11,797 kilograms. A recreational vehicle such as a motor home or a pickup with a camper is not considered a qualified motor vehicle when an individual uses it exclusively for personal pleasure. Vehicles used in connection with a business operation are not considered recreational..

 
<br/><br/>
<b>What are DOT Hours of Service?</b>
<p>Hours of Service (HOS) regulations help ensure overall road safety by governing the number of hours truck drivers can drive and work. In general, HOS rules regulate the maximum number of hours that can be driven, specify mandatory break times and off times, and duty cycles.</p>

 
<br/><br/>
<b>How do I register for DataQs?</b>
<p>All users are required to register with the DataQs system. By default, new users are assigned general public access rights. To register, click on the “New to DataQs?” button from the login box on the home page. Follow the on-screen instructions for completing the registration process and to set up a DataQs account.</p>

 
<br/>
<b>Why is CSA being implemented?</b>
<p>FMCSA’s mission is to improve safety by reducing crashes. Over the past few years, the rate of crash reduction has slowed, prompting FMCSA to take a fresh look at how the agency evaluates the safety of motor carriers and drivers and to explore ways to improve its safety monitoring, evaluation and intervention processes. CSA is the result of this comprehensive examination. CSA will enable FMCSA and its state partners to assess the safety performance of a greater segment of the industry and to intervene with more carriers to change unsafe behavior early.</p>


</div>
<br/>

<div className="dmainr6">
<div className="left">
<form onSubmit={handleformsubmit}>
<h2>Want to talk with us?</h2>
{sendpopup && <div className="sendpopup" style={{backgroundColor:'#fa9720',fontWeight:'bold',fontSize:'21px',borderRadius:'10px 10px', color:'white', padding:'1rem'}}>
  {sendpopup}
</div>}
Our team of professional logistics consultants are experienced
<div className="message">
<input type="text" style={{backgroundColor:'transparent', color:'white', outline:'none', border:'none', borderBottom:'1px solid white'}}  placeholder='Your Message' name="message" onChange={formchange} required/>
</div>
<div className="row">
<div className='i1'>
<input type="text" style={{backgroundColor:'transparent', color:'white', outline:'none', border:'none', borderBottom:'1px solid white'}}  placeholder='Your Name' name="name" onChange={formchange} required/></div>

<div className='i2'>
<input type="email" style={{backgroundColor:'transparent', color:'white', outline:'none', border:'none', borderBottom:'1px solid white'}} placeholder='Your Email' name="email" onChange={formchange} required/></div>
<div className='i3'>
<input type="number" style={{backgroundColor:'transparent', color:'white', outline:'none', border:'none', borderBottom:'1px solid white'}} placeholder='Your Phone' name="phone" onChange={formchange} required/></div>
</div>
<div className="send">

  <button type="submit">Send</button>
</div>
</form>
</div>
</div>



<br/>
<br/>

<Footer/>

    </div> 

    
      
   </>
  )
}

export default Amain

















