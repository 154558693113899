import React, { useState } from 'react'
import '../Atab/Atab.css'



function Atab() {


const [antform, setantform] = useState({});

const [antres, setantres] = useState('');

const [dtform, setdtform] = useState({});

const [dtres, setdtres] = useState('');

const[utform, setutform] = useState({});

const[utres, setutres] = useState('');



  const antchange=(e)=>{
setantform({
  ...antform,
  [e.target.name]: e.target.value
});
console.log(e.target.name, e.target.value);

}


const dtchange=(e)=>{

  setdtform({
    ...dtform,
[e.target.name]: e.target.value,
  });

  console.log(e.target.name, e.target.value,);
}

const utchange=(e)=>{

setutform({
  ...utform,
  [e.target.name]: e.target.value
});

}




//-------------------------------------------------add new tab
const addnewtabsubmit=async(e)=>{
  e.preventDefault();


const responce = await fetch(`${process.env.REACT_APP_API_URL}/addnewtab`, {
  method:'POST',
  body:JSON.stringify(antform),
  headers:{'Content-Type':'application/json'}
})

if(responce.ok)
{
const data =  await responce.json();
setantres(data.message);
setTimeout(() => {
  setantres('');
}, 2000);
}
else
{
  console.log('tab is NOT successfuly added')
}

  e.target.reset();
}

//---------------------------------------------Update tab

const updatetabsubmit= async(e)=>{
  e.preventDefault();

  const responce = await fetch(`${process.env.REACT_APP_API_URL}/updatetab`, {
    method:'POST',
    body:JSON.stringify(utform),
    headers:{'Content-Type':'application/json'}
  })

if(responce.ok)
{
  const data =  await responce.json();
  setutres(data.message);
setTimeout(() => {
  setutres('');
}, 2000);
}
else
{
  setutres("There is problem in Updating tabs");
}

e.target.reset();

}

//---------------------------------------------Delete
const deletetabsubmit= async(e)=>{
  e.preventDefault();

  const responce = await fetch(`${process.env.REACT_APP_API_URL}/deletetab`, {
    method:'POST',
    body:JSON.stringify(dtform),
    headers:{'Content-Type':'application/json'}
  })

  if(responce.ok)
  {
  const data =  await responce.json();
  setdtres(data.message);
setTimeout(() => {
  setdtres('');
}, 2000);
  }
  else
  {
    setdtres("There is problem in deleting Tab");
  }
e.target.reset();
}




  return (
   <>
   
   <div className="admintabs">
<br/>

<h2>Add New Tab</h2>

{ antres && <div style={{width:'100%', textAlign:'center', padding:'0.5rem', backgroundColor:'orange', color:'white', fontSize:'21px'}}>
{antres}
</div>}

<form method='POST' onSubmit={addnewtabsubmit}>

  <input type="text" placeholder='Enter Tab Title' name="tabtitle" onChange={antchange}  required/>

  <input type="text" placeholder='Enter Tab description' name="tabdescription" onChange={antchange} required/>

  <input type="number" placeholder='Enter Tab value price' name="tabprice" onChange={antchange} required/>

  <input type="text" placeholder='Enter Tab para1' name="tabpara1" onChange={antchange} required/>

  <input type="text" placeholder='Enter Tab para2' name="tabpara2" onChange={antchange} required/>

  <input type="text" placeholder='Paste Iframe of video (Youtube etc)' onChange={antchange} name="tabvideo" />

<select required name="tabcategory" onChange={antchange}>
  <option>Choose Category</option>
  <option >dispatch</option>
  <option >safety</option>
  <option >logbook</option>
  <option >IFTA</option>
  <option >Accounting</option>
  <option >Administration</option>
  <option >Hiring Drivers</option>
  <option >TMS</option>
  <option >Setup MC</option>
  <option >Car Hauler</option>
</select>


<button type="submit">Add new Tab</button>

</form>

<h2>Update Tab</h2>
{ true && <div style={{width:'100%', textAlign:'center', padding:'0.5rem', backgroundColor:'whitesmoke', color:'gray', fontSize:'21px'}}>
Enter relevant Tab Title and category to change its content
</div>}

{ utres && <div style={{width:'100%', textAlign:'center', padding:'0.5rem', backgroundColor:'orange', color:'white', fontSize:'21px'}}>
{utres}
</div>}

<form method='POST' onSubmit={updatetabsubmit}>

<input type="text" placeholder='Enter Tab Title' name="tabtitle" onChange={utchange}  required/>

<input type="text" placeholder='Enter Tab description' name="tabdescription" onChange={utchange} required/>

<input type="number" placeholder='Enter Tab value price' name="tabprice" onChange={utchange} required/>

<input type="text" placeholder='Enter Tab para1' name="tabpara1" onChange={utchange} required/>

<input type="text" placeholder='Enter Tab para2' name="tabpara2" onChange={utchange} required/>

<input type="text" placeholder='Paste Iframe of video (Youtube etc)' onChange={utchange} name="tabvideo" />

<select required name="tabcategory" onChange={utchange}>
<option value="">Choose Category</option>
<option >dispatch</option>
<option >safety</option>
<option >logbook</option>
<option >IFTA</option>
<option >Accounting</option>
<option >Administration</option>
<option >Hiring Drivers</option>
<option >TMS</option>
<option >Setup MC</option>
<option >Car Hauler</option>
</select>

<button type="submit">Update Tab</button>

</form>



<h2>Delete Tab</h2>

{ dtres && <div style={{width:'100%', textAlign:'center', padding:'0.5rem', backgroundColor:'orange', color:'white', fontSize:'21px'}}>
{dtres}
</div>}

<form method='POST' onSubmit={deletetabsubmit}>

<input type="text" placeholder='Enter Exact Tab Title to Delete' name="tabtitle" onChange={dtchange} required/>

<select required name="tabcategory" onChange={dtchange}>
<option>Choose Category</option>
<option >dispatch</option>
<option >safety</option>
<option >logbook</option>
<option >IFTA</option>
<option >Accounting</option>
<option >Administration</option>
<option >Hiring Drivers</option>
<option >TMS</option>
<option >Setup MC</option>
<option >Car Hauler</option>
</select>

<button type="submit">Delete Tab</button>


</form>






   </div>
  
   </>
  )
}

export default Atab
