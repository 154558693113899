import React, { useEffect, useState } from 'react'
import '../Dispatch/Dmain.css'
import { FaTruckMoving } from "react-icons/fa";
import { ImPower } from "react-icons/im";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TfiDownload } from "react-icons/tfi";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { BsArrowUpRight } from "react-icons/bs";
import Footer from '../Footer/Footer';



//6Le5QxgqAAAAABMDBKo6APEVmQSXjp8oIhDu9s80

function Cmain() {





useEffect(() => {
  window.scrollTo(0, 0);
}, []);





  let currentIndex=0;

  const slideshow=()=>{
  
    const slider = document.querySelector('.slider');
  const totalslides = document.querySelectorAll('.box');
  
  console.log('total nodes are ',totalslides);
  
   // Correctly wrap the currentIndex
   if (currentIndex < 0) {
    currentIndex = totalslides.length - 1;
  }
  if (currentIndex >= totalslides.length) {
    currentIndex = 0;
  }
  
  // Adjust the transform property to show the correct slide
  slider.style.transform = `translateX(-${currentIndex * 22}rem)`;
    
  }
  
  const handleleft=()=>{
    currentIndex=currentIndex-1;
    slideshow();
  }


  const handleright=()=>{
    currentIndex=currentIndex+1;
    slideshow();
  }












  

const [cform , setcform] = useState({});
const [sendpopup, setsendpopup] = useState('');

  const formchange=(e)=>{

    setcform({
      ...cform,
      [e.target.name]: e.target.value
    });
  }

//-------------------------------------------------------------------------
  const handleformsubmit=async(e)=>{

    e.preventDefault();

const responce = await fetch(`${process.env.REACT_APP_API_URL}/cformsubmit`, {
  method:'POST',
  body:JSON.stringify(cform),
  headers:{'Content-Type':'application/json'}
})

if(responce.ok)
{
const data =  await responce.json();
setsendpopup(data.message);
setTimeout(() => {
  setsendpopup('');
}, 2000);
}
else
{
  console.log('form NOT successfuly submit')
}

  e.target.reset();

  }
  

const [tab, settab] = useState([]);

const fetchtabs=async()=>{


 const responce =  await fetch(`${process.env.REACT_APP_API_URL}/carhaulertab`,{ method:'GET'});

 if(responce.ok)
 {
const data =  await responce.json();
settab(data);
 }
 else
 {
console.log("Problem in fetching");
 }
}
//--------------------------------------------------------------------------------------------

const [slider , setslider] = useState([]);
const  fetchslider =async()=>{


  const responce =  await fetch(`${process.env.REACT_APP_API_URL}/carhaulerslider`,{ method:'GET'});
 
  if(responce.ok)
  {
 const data =  await responce.json();
 setslider(data);
  }
  else
  {
 console.log("Problem in fetching");
  }
 }

 const [bgimg , setbgimg] = useState([]);
 const fetchbgimg= async()=>{

  const responce =  await fetch(`${process.env.REACT_APP_API_URL}/carhaulerbgimg`,{ method:'GET'});
 
  if(responce.ok)
  {
 const data =  await responce.json();
 setbgimg(data);
  }
  else
  {
 console.log("Problem in fetching");
  }

 }


  useEffect(()=>{
    fetchtabs();
    fetchslider();
    fetchbgimg();
  }, [])

  const [openIndex, setOpenIndex] = useState(null);

  const toggleDropdown = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };






  
  return (
   <>
  
   <div className="dmain">
    
    {
      bgimg.map( image =>(
    <div className="topimg" key={image._id}> 
<img src={`${process.env.REACT_APP_API_URL}${image.BimagePath}`} />
    </div>
       ) )
}

<div className="r2">


<div className="left">
  <div className="fleetsize">
  Fleet Size
  </div>
  <div className="detail">
  Select the trucks amount to get a more detailed offer
  </div>
<br/>
<div className="buttons">
  <button>1</button>
  <button>2-5</button>
  <button>6-10</button>
  <button>21-50</button>
  <button>51-100</button>
  <button>101 +</button>
</div>
</div>


<div className="vline">
  
</div>

<div className="right">
<div className="fleetsize">
Truck Type
  </div>
  <div className="detail">
  Select the type of your truck for additinal offers
  </div>
<br/>
<div className="buttons">
  <button> <FaTruckMoving style={{fontSize:'25px',color:'black'}}/> DryVan</button>
  <button ><FaTruckMoving style={{fontSize:'25px',color:'black'}}/> Reefer</button>
  <button><FaTruckMoving style={{fontSize:'25px',color:'black'}}/> FI/ST Deck</button>
  <button><ImPower style={{fontSize:'20px',color:'black'}} /> Power Only</button>
</div>
</div>

</div>
    <br/>






    <div className="r3">

    {tab.map((value, index) => (
        <>
          <div className="section1" key={value._id}>
            <div className="left">
              <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '28px' }}>
                {value.Ttabtitle}
              </div>
              <div style={{ color: '#919295' }}>
                {value.Ttabdescription}
              </div>
            </div>

            <div className="right">
              <div className="number">
                {value.Ttabprice}%
              </div>
              <div className="button" onClick={() => toggleDropdown(index)}>
                <RiArrowDropDownLine id="dropicon" />
              </div>
            </div>
          </div>

          {openIndex === index && (
            <div className="sectiondropdown">
              <div className="left">
                <div className="a1">
                  {value.Ttabtitle}
                </div>
                <div className="a2">
                  {value.Ttabpara1}
                </div>
                <div className="a3">
                  {value.Ttabpara2}
                </div>
                <div className="a4">
                  <TfiDownload style={{ fontSize: '40px', color: 'red' }} />
                  Download Offer
                </div>
                <div className="a5">
                  <form method='POST' onSubmit={handleformsubmit}>
                    <h3>Want to talk with us?</h3>
                    {sendpopup && <div className="sendpopup" style={{backgroundColor:'#fa9720',fontWeight:'bold',fontSize:'21px',borderRadius:'10px 10px', color:'white', padding:'1rem'}}>
                       {sendpopup}
                    </div>}
                    <label>Name</label>
                    <input type="text" placeholder='Your Name' name="name" onChange={formchange} required />
                    <label>Phone</label>
                    <input type="number" placeholder='Your Phone' name="phone" onChange={formchange} required />
                    <label>Email</label>
                    <input type="email" placeholder='Your Email' name="email" onChange={formchange} required />
                    <label>Message</label>
                    <input type="text" placeholder='Your Message' name="message" onChange={formchange} required />
                    <button type="submit">Send</button>
                  </form>
                </div>
              </div>
              <div className="right">
                <div className="b1">
                  <div className="n">
                    {value.Ttabprice}%
                  </div>
                  <div className="b" onClick={() => toggleDropdown(index)}>
                    <FaChevronUp id="upicon" />
                  </div>
                </div>
                <div className="b2">
                <div dangerouslySetInnerHTML={{ __html: value.Ttabvideo }} />
                </div>
              </div>
            </div>
          )}
        </>
      ))}
</div>


<br/><br/>



<div className="r4">
<div className="upper">
<div style={{fontSize:'40px' ,fontWeight:'bold'}}>Other Services</div>
<div className='rlbutton'>
<FaChevronLeft style={{cursor:'pointer'}} onClick={handleleft} />
<FaChevronRight style={{cursor:'pointer'}} onClick={handleright} />
</div>
</div>
<div className="lower">
<div className="slider">
{
  slider.map( val =>(

<div className="box" key={val._id} >
    <div className="b1">
<div className="upper">
<h2>{val.Sstitle}</h2>
  <p>{val.Ssdescription}</p>
</div>
<div className="lower">

<div className="left">{val.Ssprice} </div>
<div className="right"><BsArrowUpRight style={{fontSize:'35px'}} /></div>
</div>
    </div>
  </div>

  ))
}
</div>
</div>
</div>




<br/>



<div className="r5">

<h2>The Truck Dispatching Company to Keep Your Business Moving</h2>
<p>As a truck driver or logistics business owner, you understand the importance of reliable dispatching services. At AOne Dispatchers, a project of AOne Merchants LLC, we are committed to providing the support you need to keep your trucking operation running smoothly.

Located in Virginia Beach, Virginia, we offer our dispatching services to trucking companies across the nation. We understand that your business plays a vital role in our society and economy, and we are here to help you provide top-quality logistics services.

Contact us by email or phone to learn more about how we can support your business. Our team is dedicated to providing you with the information and quotes you need to get started. At AOne Dispatchers, we are passionate about helping you succeed.</p>
  



<h2>What Our Truck Car Hauler Services Offer:</h2>
<br/>
  <b>Swift and Efficient Truck Dispatching</b>
Efficient and timely dispatching is critical for logistics success. Our licensed dispatchers provide quick turnaround times and real-time communication, laying the foundation for your job to be completed correctly. Our team works hard to minimize your frustrations and get you on the road faster.

 
<br/><br/>
<b>What do I need to start?</b>
You have to have Your truck/trailer and documentation ready. We can start anytime after the contract is signed

 
<br/><br/>
<b>What rate can you guarantee?</b>
Rate always depends on the season and on the market , we are always fighting for the price to guarantee you the best possible rate from point A to point B

 
<br/><br/>
<b>What is the main point to keep the driver happy?</b>
Keep him always busy – this is the key.

 
<br/><br/>
<b>Are you checking load status?</b>
Yes! We are calling and making sure the load is paid and ready before the load is assigned to the driver.

</div>
<br/>

<div className="dmainr6">
<div className="left">
<form onSubmit={handleformsubmit}>
<h2>Want to talk with us?</h2>
{sendpopup && <div className="sendpopup" style={{backgroundColor:'#fa9720',fontWeight:'bold',fontSize:'21px',borderRadius:'10px 10px', color:'white', padding:'1rem'}}>
  {sendpopup}
</div>}
Our team of professional logistics consultants are experienced
<div className="message">
<input type="text" style={{backgroundColor:'transparent', color:'white', outline:'none', border:'none', borderBottom:'1px solid white'}}  placeholder='Your Message' name="message" onChange={formchange} required/>
</div>
<div className="row">
<div className='i1'>
<input type="text" style={{backgroundColor:'transparent', color:'white', outline:'none', border:'none', borderBottom:'1px solid white'}}  placeholder='Your Name' name="name" onChange={formchange} required/></div>

<div className='i2'>
<input type="email" style={{backgroundColor:'transparent', color:'white', outline:'none', border:'none', borderBottom:'1px solid white'}} placeholder='Your Email' name="email" onChange={formchange} required/></div>
<div className='i3'>
<input type="number" style={{backgroundColor:'transparent', color:'white', outline:'none', border:'none', borderBottom:'1px solid white'}} placeholder='Your Phone' name="phone" onChange={formchange} required/></div>
</div>
<div className="send">

  <button type="submit">Send</button>
</div>
</form>
</div>
</div>



<br/>
<br/>

<Footer/>

    </div> 

    
      
   </>
  )
}

export default Cmain




















