import React, { useEffect, useState } from 'react'
import '../Navbar/Navbar.css'
import { FaCartShopping } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';


function Navbar() {

  const [dispatch, setdispatch] = useState(true);
  const [safety, setsafety] = useState(false);
  const [logbook, setlogbook] = useState(false);
  const [ifta, setifta] = useState(false);
  const [accounting, setaccounting] = useState(false);
  const [administration, setadministration] = useState(false);
  const [hdriver, sethdriver] = useState(false);
  const [tms, settms] = useState(false);
  const [mc, setmc] = useState(false);
  const [ch, setch] = useState(false);

  
   const style1 = dispatch ? { borderBottom:'3px solid #fa9720'} : {};
   const style2 = safety ? { borderBottom:'3px solid #fa9720'} : {};
   const style3 = logbook ? { borderBottom:'3px solid #fa9720'} : {};
   const style4 = ifta ? { borderBottom:'3px solid #fa9720'} : {};
   const style5 = accounting ? { borderBottom:'3px solid #fa9720'} : {};
   const style6 = administration ? { borderBottom:'3px solid #fa9720'} : {};
   const style7 = hdriver ? { borderBottom:'3px solid #fa9720'} : {};
   const style8 = tms ? { borderBottom:'3px solid #fa9720'} : {};
   const style9 = mc ? { borderBottom:'3px solid #fa9720'} : {};
   const style10 = ch ? { borderBottom:'3px solid #fa9720'} : {};

  const settab=(v)=>{

    if(v==="dispatch")
    {
      setdispatch(true);
      //-----------------
      setsafety(false);
      setlogbook(false);
      setifta(false);
      setaccounting(false);
      setadministration(false);
      sethdriver(false);
      settms(false);
      setmc(false);
      setch(false);
    }
    if(v==="safety")
    {
      setdispatch(false);
      //-----------------
      setsafety(true);
      setlogbook(false);
      setifta(false);
      setaccounting(false);
      setadministration(false);
      sethdriver(false);
      settms(false);
      setmc(false);
      setch(false);
    }
    if(v==="logbook")
    {
      setdispatch(false);
      //-----------------
      setsafety(false);
      setlogbook(true);
      setifta(false);
      setaccounting(false);
      setadministration(false);
      sethdriver(false);
      settms(false);
      setmc(false);
      setch(false);
    }
    if(v==="ifta")
    {
      setdispatch(false);
      //-----------------
      setsafety(false);
      setlogbook(false);
      setifta(true);
      setaccounting(false);
      setadministration(false);
      sethdriver(false);
      settms(false);
      setmc(false);
      setch(false);
    }
    if(v==="accounting")
    {
      setdispatch(false);
      //-----------------
      setsafety(false);
      setlogbook(false);
      setifta(false);
      setaccounting(true);
      setadministration(false);
      sethdriver(false);
      settms(false);
      setmc(false);
      setch(false);
    }
    if(v==="administration")
    {
      setdispatch(false);
      //-----------------
      setsafety(false);
      setlogbook(false);
      setifta(false);
      setaccounting(false);
      setadministration(true);
      sethdriver(false);
      settms(false);
      setmc(false);
      setch(false);
    }
    if(v==="hd")
    {
      setdispatch(false);
      //-----------------
      setsafety(false);
      setlogbook(false);
      setifta(false);
      setaccounting(false);
      setadministration(false);
      sethdriver(true);
      settms(false);
      setmc(false);
      setch(false);
    }
    if(v==="tms")
    {
      setdispatch(false);
      //-----------------
      setsafety(false);
      setlogbook(false);
      setifta(false);
      setaccounting(false);
      setadministration(false);
      sethdriver(false);
      settms(true);
      setmc(false);
      setch(false);
    }
    if(v==="mc")
    {
      setdispatch(false);
      //-----------------
      setsafety(false);
      setlogbook(false);
      setifta(false);
      setaccounting(false);
      setadministration(false);
      sethdriver(false);
      settms(false);
      setmc(true);
      setch(false);
    }
    if(v==="ch")
    {
      setdispatch(false);
      //-----------------
      setsafety(false);
      setlogbook(false);
      setifta(false);
      setaccounting(false);
      setadministration(false);
      sethdriver(false);
      settms(false);
      setmc(false);
      setch(true);
    }
  }

const [logo , setlogo] = useState([]);

  const fetchlogo=async()=>{

    const responce  = await fetch(`${process.env.REACT_APP_API_URL}/fetchlogo`,{method:'GET'})

    if(responce.ok)
    {
const data = await responce.json();
setlogo(data);
    }
    else
    {
console.log('Logo Not fetch properly');
    }
  }





  const [hnumber , sethnumber] = useState([]);
  const fetchnumber=async()=>{

    const responce  = await fetch(`${process.env.REACT_APP_API_URL}/fetchhnumber`,{method:'GET'})

    if(responce.ok)
    {
const data = await responce.json();
sethnumber(data);
    }
    else
    {
console.log('Logo Not fetch properly');
    }
  }

  console.log(process.env.REACT_APP_API_URL);

  useEffect(()=>{
fetchlogo();
fetchnumber();
  },[])


  return (
 <>
 
 <div className="navbar">

<div className="upper">


<div className="upper2">
<ul>

<li onClick={()=>{settab("dispatch")}} style={style1} >Dispatch</li>
<li onClick={()=>{settab("safety")}} style={style2}>Safety</li>
<li onClick={()=>{settab("logbook")}} style={style3}>Logbook</li>
<li onClick={()=>{settab("ifta")}} style={style4}>IFTA</li>
<li onClick={()=>{settab("accounting")}} style={style5}>Accounting</li>
<li onClick={()=>{settab("administration")}} style={style6}>Administration</li>
<li onClick={()=>{settab("hd")}} style={style7}>Hiring Drivers</li>
<li onClick={()=>{settab("tms")}} style={style8}>TMS</li>
<li onClick={()=>{settab("mc")}} style={style9}>Setup MC</li>
<li onClick={()=>{settab("ch")}} style={style10}>Car hauler</li>

</ul>

<div className="cart">
<FaCartShopping style={{fontSize:'21px'}}/>
</div>
</div>



</div>

<div className="lower">

<div className="lower2">
<div className="left">
  {/* { logo.map( val =>(

<NavLink to="Dispatch/Dmain" style={{textDecoration:'none', color:'inherit'}} >
  <div className='imgdiv'>
 
  <img src={`${process.env.REACT_APP_API_URL}/${val.imagePath}`} style={{width:'230px'}} />

  </div>
  </NavLink>
    )  )
} */}
<NavLink to="Dispatch/Dmain" style={{textDecoration:'none', color:'inherit'}} >
  <div className='imgdiv'>
  <img src="tlogo.png" style={{width:'230px'}} />
  </div>
  </NavLink>


{
  dispatch &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Dispatch/Dmain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Dispatch/Dservices" style={{textDecoration:'none', color:'inherit'}} ><li>Services</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Dispatch/Dwhyus" style={{textDecoration:'none', color:'inherit'}} ><li>Why Us</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Dispatch/Dcontacts" style={{textDecoration:'none', color:'inherit'}} ><li>Contacts</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Dispatch/Dprices" style={{textDecoration:'none', color:'inherit'}} ><li>Prices</li></NavLink>
</ul>
}

{
  safety &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Safety/Smain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Safety/Sservices" style={{textDecoration:'none', color:'inherit'}} ><li>Services</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Safety/Swhyus" style={{textDecoration:'none', color:'inherit'}} ><li>Why Us</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Safety/Scontacts" style={{textDecoration:'none', color:'inherit'}} ><li>Contacts</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Safety/Sprices" style={{textDecoration:'none', color:'inherit'}} ><li>Prices</li></NavLink>
</ul>

}
{
  logbook &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Logbook/Lmain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Logbook/Lservices" style={{textDecoration:'none', color:'inherit'}} ><li>Services</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Logbook/Lwhyus" style={{textDecoration:'none', color:'inherit'}} ><li>Why Us</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Logbook/Lcontacts" style={{textDecoration:'none', color:'inherit'}} ><li>Contacts</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Logbook/Lprices" style={{textDecoration:'none', color:'inherit'}} ><li>Prices</li></NavLink>
</ul>

}
{
  ifta &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Ifta/Imain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Ifta/Iservices" style={{textDecoration:'none', color:'inherit'}} ><li>Services</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Ifta/Iwhyus" style={{textDecoration:'none', color:'inherit'}} ><li>Why Us</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Ifta/Icontacts" style={{textDecoration:'none', color:'inherit'}} ><li>Contacts</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Ifta/Iprices" style={{textDecoration:'none', color:'inherit'}} ><li>Prices</li></NavLink>
</ul>

}
{
  accounting &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Accounting/Amain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Accounting/Aservices" style={{textDecoration:'none', color:'inherit'}} ><li>Services</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Accounting/Acontacts" style={{textDecoration:'none', color:'inherit'}} ><li>Contacts</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Accounting/Aprices" style={{textDecoration:'none', color:'inherit'}} ><li>Prices</li></NavLink>
</ul>

}
{
  administration &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Administration/Admain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Administration/Adservices" style={{textDecoration:'none', color:'inherit'}} ><li>Services</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Administration/Adwhyus" style={{textDecoration:'none', color:'inherit'}} ><li>Why Us</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Administration/Adcontacts" style={{textDecoration:'none', color:'inherit'}} ><li>Contacts</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Administration/Adprices" style={{textDecoration:'none', color:'inherit'}} ><li>Prices</li></NavLink>
</ul>

}
{
  hdriver &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Hiringdrivers/Hmain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Hiringdrivers/Happlication" style={{textDecoration:'none', color:'inherit'}} ><li>Application</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Hiringdrivers/Hneeddrivers" style={{textDecoration:'none', color:'inherit'}} ><li>Need Drivers</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Hiringdrivers/Hprices" style={{textDecoration:'none', color:'inherit'}} ><li>prices</li></NavLink>
</ul>

}
{
  tms &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Tms/Tmain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Tms/Tcontracts" style={{textDecoration:'none', color:'inherit'}} ><li>Contracts</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Tms/Tprices" style={{textDecoration:'none', color:'inherit'}} ><li>prices</li></NavLink>
</ul>

}
{
  mc &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Mc/Mmain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Mc/Mservices" style={{textDecoration:'none', color:'inherit'}} ><li>Services</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Mc/Mwhyus" style={{textDecoration:'none', color:'inherit'}} ><li>Why Us</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Mc/Mcontacts" style={{textDecoration:'none', color:'inherit'}} ><li>Contacts</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Mc/Mprices" style={{textDecoration:'none', color:'inherit'}} ><li>Prices</li></NavLink>
</ul>

}
{
  ch &&
<ul>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Carhauler/Cmain" style={{textDecoration:'none', color:'inherit'}} ><li>Main</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Carhauler/Ccontacts" style={{textDecoration:'none', color:'inherit'}} ><li>Contact Us</li></NavLink>
<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="Carhauler/Cprices" style={{textDecoration:'none', color:'inherit'}} ><li>prices</li></NavLink>
</ul>

}

</div>


<div className="rights">
  {
    hnumber.map( v =>(
    <p>{v.Hheadernumber}<br/> <span>Feel free to call us anytime</span></p>
    ))
  }
</div>

</div>


</div>

 </div>
 
 </>
  )
}

export default Navbar
