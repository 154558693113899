import React, { useState } from 'react';
import '../Aimage/Aimage.css';

function Aimage() {
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState('');
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', file);
    formData.append('tabcategory', category);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/uploadbgimg`, {
      method: 'POST',
      body: formData,
    });


    if(response.ok)
    {
      const result = await response.json();
      setMessage(result.message);
      setTimeout(() => {
        setMessage('');
      }, 2000);
    }
  };

  return (
    <>
      <div className="admintabs">
        <br />
        <h2>Add Background images</h2>

        {message && (
          <div style={{ width: '100%', textAlign: 'center', padding: '0.5rem', backgroundColor: 'orange', color: 'white', fontSize: '21px' }}>
            {message}
          </div>
        )}

        <form method='POST' style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
          <input type="file" accept="image/*" onChange={handleFileChange} required />
          <select required name="tabcategory" onChange={handleCategoryChange}>
            <option value="">Choose Category</option>
            <option value="dispatch">dispatch</option>
            <option value="safety">safety</option>
            <option value="logbook">logbook</option>
            <option value="IFTA">IFTA</option>
            <option value="Accounting">Accounting</option>
            <option value="Administration">Administration</option>
            <option value="Hiring Drivers">Hiring Drivers</option>
            <option value="TMS">TMS</option>
            <option value="Setup MC">Setup MC</option>
            <option value="Car Hauler">Car Hauler</option>
          </select>
          <button type="submit">Save</button>
        </form>
      </div>
    </>
  );
}

export default Aimage;
